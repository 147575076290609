import {
  Box,
  Flex,
  FlexProps,
  Text,
} from '@chakra-ui/react';
import { InformationTag } from '../InformationTag';
import {
  Image,
  PcDisplayBlock,
  SpDisplayBlock
} from '@/components';
import { dayjs } from '@/utils/dayjs';
import { toText } from '@/utils/html';
import { getThumbnailUrl } from '@/utils/thumbnailUrl';

export type ArticleListItemType = {
  displayId: string;
  title: string;
  body?: string;
  content?: string;
  tags?: string[];
  publishedStartAt?: string;
  eyeCatchingImage?: string | null;
};

type ArticleListItemProps = {
  articleListItem: ArticleListItemType;
} & FlexProps;

export const ArticleListItem = ({
  articleListItem: {
    title,
    body,
    content,
    publishedStartAt,
    tags,
    eyeCatchingImage,
  },
  ...rest
}: ArticleListItemProps) => {
  const bodyText = body || content || '';
  const image = getThumbnailUrl(eyeCatchingImage, bodyText, 'src');

  return (
    <Flex
      my={{ base: 5, md: 4 }}
      justifyContent={'center'}
      alignItems={'center'}
      maxHeight={{ base: '160px', lg: '160px' }}
      {...rest}
    >
      <Box
        flex={1}
      >
        <Box
          position={'relative'}
          height={{ base: '120px', lg: '160px' }}
          width={{ base: '120px', lg: '160px' }}
          mx={'auto'}
        >
          <Image
            src={image}
            alt={title}
            fill
            style={{ objectFit: 'contain' }}
            unoptimized={true}
          />
        </Box>
      </Box>
      <Box
        display={'grid'}
        flex={5}
      >
        <Flex
          flexDirection={'column'}
          justifyContent={'flex-start'}
        >
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            width={'fit-content'}
          >
            <PcDisplayBlock>
              <Box
                px={5}
                py={8}
                my={5}
                borderRight={'1px solid'}
                borderColor={'brandSecondary.300'}
              >
                <Text
                  textStyle={'h6'}
                >
                  {dayjs(publishedStartAt).format('YYYY')}
                </Text>
                <Text
                  textStyle={'h3'}
                  fontWeight={'bold'}
                >
                  {dayjs(publishedStartAt).format('MM.DD')}
                </Text>
              </Box>
            </PcDisplayBlock>
            <PcDisplayBlock
              pl={5}
              my={10}
            >
              <Box
                style={{
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                }}
              >
                <Text
                  as={'h3'}
                  textStyle={'h4'}
                  fontWeight={'bold'}
                  color={'brandSecondary.500'}
                >
                  {title}
                </Text>
              </Box>
              <Box
                style={{
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                }}
                wordBreak={'break-all'}
              >
                <Text textStyle={'h5'}>{toText(bodyText).substring(0, 100)}</Text>
              </Box>
              <Box
                display={'block'}
                pt={{ base: 2, lg: 3 }}
                px={{ base: 3, lg: 0 }}

              >
                <Flex
                  flexWrap={'wrap'}
                >
                  {tags && tags.map((tag) =>
                    <Box
                      key={tag}
                    >
                      <InformationTag
                        tagName={tag}
                      />
                    </Box>
                  )}
                </Flex>
              </Box>
            </PcDisplayBlock>
          </Flex>

          <SpDisplayBlock
            pl={{ base: 3 }}
          >
            <Flex
              flexDirection={'column'}
            >
              <Text
                textStyle={'h8'}
                fontWeight={'bold'}
                color={'gray.400'}
              >
                {dayjs(publishedStartAt).format('YYYY.MM.DD')}
              </Text>
              <Box
                style={{
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 4,
                }}

              >
                <Text
                  textStyle={'h7'}
                  fontWeight={'bold'}
                  pt={2}
                  pb={1}
                  color={'brandSecondary.500'}
                >
                  {title}
                </Text>
              </Box>
              <Box
                style={{
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                }}
                wordBreak={'break-all'}
              >
                <Text>{toText(bodyText).substring(0, 100)}</Text>
              </Box>
            </Flex>
          </SpDisplayBlock>
          <SpDisplayBlock>
            <Box
              display={'block'}
              pt={{ base: 2, lg: 0 }}
              px={{ base: 3, lg: 5 }}
              mt={{ base: 0, lg: -5 }}
            >
              <Flex
                flexWrap={'wrap'}
              >
                {tags && tags.map((tag) =>
                  <Box
                    key={tag}
                  >
                    <InformationTag
                      tagName={tag}
                    />
                  </Box>
                )}
              </Flex>
            </Box>
          </SpDisplayBlock>
        </Flex>
      </Box>
    </Flex>
  );
};
